.modal-close {
  background: auto;
  height: 48px;
  position: absolute;
  right: 0;
  top: 0;
  width: 48px;
}

.modal-content {
  overflow: hidden !important;
}

.modal-card-foot {
  box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.3);
}

strong {
  color: inherit;
}
@media screen and (max-width: 768px) {
  .level-left + .level-right {
    margin-top: 0;
  }
}
