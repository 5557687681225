@mixin flex-column-vertically-centered {
  display: flex;
  flex-direction: column;

  > * {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.ereader-activity {
  background-color: #c25200;
  background-image: url("./../images/backgrounds/ereader-bg.png");
  width: 100%;
  height: 100%;
  border-bottom: 1px solid black;
  padding-top: 10px;
  padding-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}

.ereader-view-container {
  width: 80vw;
  height: 100%;
}

.epub-view {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.epub-view > iframe {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ereader-button {
  background-color: #c25200;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
  width: 80px;
  height: 80px;
}

.ereader-button:hover {
  background-color: #f36700;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.3);
}

.ereader-loading-cube {
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}

.ereader-loading-cube > .sk-folding-cube .sk-cube:before {
  background-color: white;
}

#ereader-audio-player-button {
  left: 2vw;
  top: 5vw;
  position: fixed;
  visibility: hidden;
  font-size: 48px;
}

#ebook-nav-prev {
  position: absolute;
  left: 2vw;
  top: 40%;
  border: 2px solid #ffffff;
  font-size: 48px;
}

#ebook-nav-next {
  position: absolute;
  right: 2vw;
  top: 40%;
  border: 2px solid #ffffff;
  font-size: 48px;
}
