/*
  Activity Modifiers
 */

.is-margin-auto {
  margin: auto;
}

.is-padding-small {
  padding: 0.5em !important;
}

.is-overlay {
  z-index: 1000;
}

.choose-subject-sequential,
.is-activity-intro,
.quiz-intro,
.quiz-outro,
.video-recording-intro,
.video-recording,
.is-quest-intro,
.is-quest-outro {
  min-height: 100vh;
  width: 100vw;
  background-image: url("./../images/backgrounds/k2-background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 85%;
}

.seasonal-theme-fall,
.seasonal-theme-halloween {
  .choose-subject-sequential,
  .is-activity-intro,
  .quiz-intro,
  .quiz-outro,
  .video-recording-intro,
  .video-recording,
  .is-quest-intro,
  .is-quest-outro {
    background-image: url("./../images/backgrounds/k2-autumn2019.svg");
  }
}

.seasonal-theme-winter {
  .choose-subject-sequential,
  .is-activity-intro,
  .quiz-intro,
  .quiz-outro,
  .video-recording-intro,
  .video-recording,
  .is-quest-intro,
  .is-quest-outro {
    background-image: url("./../images/backgrounds/k2-winter2019.svg");
  }
}

.choice-layout .video-recording,
.choice-layout .quiz-outro {
  background-image: none;
}

.is-activity-practice {
  background-color: $blue;

  &.is-activity-quiz {
    background-color: $white;
  }

  &.youtubevideo {
    background-color: $black;
  }
}

.has-background-green {
  background-color: #089c16;
}

.has-background-yellow {
  background-color: #fbd24f;
}

.has-background-orange {
  background-color: #fb7633;
}

.has-background-purple {
  background-color: #886ac8;
}

.has-background-blue {
  background-color: #4089fb;
}

.has-background-green-pale {
  background-color: #ecffee;
}

.has-background-orange-pale {
  background-color: #fff3ed;
}

.has-background-purple-pale {
  background-color: #f9f7fe;
}

.has-background-blue-pale {
  background-color: #e5f6ff;
}

.has-text-rocky,
.has-text-yellow {
  color: $text-yellow;
}

// $monsters: ant, bunny, cat, many-eyes, pineapple, rainbow;
$colors: green, purple, blue, orange, bright-red, dark-red;

@for $i from 0 through 100 {
  .has-text-monster-#{$i + 1} {
    @extend .has-text-#{nth($colors, ($i % length($colors)) + 1)};
  }
}

.has-text-ant,
.has-text-monster-1,
.has-text-green {
  color: $text-green;
}

.has-text-bunny,
.has-text-monster-2,
.has-text-purple {
  color: $text-purple;
}

.has-text-cat,
.has-text-monster-3,
.has-text-blue {
  color: $text-blue;
}

.has-text-many-eyes,
.has-text-monster-4,
.has-text-orange {
  color: $text-orange;
}

.has-text-pineapple,
.has-text-monster-5,
.has-text-bright-red {
  color: $text-bright-red;
}

.has-text-rainbow,
.has-text-monster-6,
.has-text-dark-red {
  color: $text-dark-red;
}

.is-reflected-horizontal {
  transform: scaleX(-1);
}

.is-fixed-position-on-mobile {
  position: fixed;
  transform: translate3d(0, 0, 0);
}

// gives us modifiers for 10, 20, 30 px border-radius
@for $i from 1 through 3 {
  .has-radius-#{$i * 10},
  .has-radius-#{$i * 10} > img {
    border-radius: $i * 10 + px;
  }
}

.is-absolute {
  position: absolute !important;
}

.is-absolutely-positioned-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
}

.is-absolutely-positioned-center-horizontal {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.is-absolutely-positioned-center-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.has-shadow {
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.5);
}

.has-pointer-events-disabled {
  pointer-events: none;
}

.has-pointer-events-enabled {
  pointer-events: auto;
}
