// Progress bar colors
progress {
  display: block;
  height: 5px;
  width: 100%;

  // This needs to be set so we can style the progress bar
  // https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-progress-bar
  -webkit-appearance: none;

  // The growing bar
  &[value]::-webkit-progress-value {
    background-color: #3ebb25;
    height: 100%;
  }

  &.is-red-base[value]::-webkit-progress-value {
    background-color: #e14747;
  }

  // The shrinking background
  &[value]::-webkit-progress-bar {
    background: rgba(0, 0, 0, 0.05);
    height: 100%;
  }
}
