/*
 * Fixed item grid does not have a variable gap between columns. It has a set of breakpoints
 * associated with a single width of content that remains the same for viewport sizes anywhere
 * in the given range.
 *
 * To create a fixed item grid associated with a specific view or "Component", the fixed item grid must
 * be a child of the component. Use the `fixed-grid` mixin to customize the grid for your needs.
 */
.FixedItemGrid {
  margin: auto;

  &__container {
    margin: auto;
    min-height: 100vh;
  }

  &__columns {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }

  &__column {
    display: flex;
    justify-content: center;
  }
}

/* ***************************************************************************************
 *  Mixin
 *
 * This creates rules for fixed grid classes that are specific for the containing code. As long as the
 * items in the grid have a defined height and width, you don't need to specify them when you include the
 * mixin for your component. You must specify the breakpoint widths for the container element in the body
 * of your mixin include.
 *
 * You may use https://www.npmjs.com/package/postcss-inline-media for shorthand.
 *
 /* *************************************************************************************** */
@define-mixin fixed-item-grid $component, $col-gap, $row-gap {
  .$(component) .FixedItemGrid {
    @mixin-content;

    &__column {
      padding: 0 calc($col-gap / 2);
      margin-top: calc($row-gap / 2);
      margin-bottom: calc($row-gap / 2);
    }
  }
}

/* ***************************************************************************************
 * Components
 *
 * This is where you implement the mixin and create fixed grids for different things
 *
 /* *************************************************************************************** */

/* Fixed item grid for the quest world home screen
*/
$quest-home-medium: (
  min-width: 723px
);
$quest-home-large: (
  min-width: 1023px
);
$quest-home-xlarge: (
  min-width: 1249px
);

@mixin fixed-item-grid ActivityStations, 40px, 24px {
  padding-top: 48px;
  min-height: 100vh;

  &__container {
    padding-bottom: 38px;
    max-width: 300px @quest-home-medium 600px @quest-home-large 900px @quest-home-xlarge 1200px;
    padding-top: 38px @quest-home-medium 38px @quest-home-large 38px @quest-home-xlarge 105px;
  }
}
