/* This serves to add an absolutely positioned layer that we're currently using for animations.
  TODO: Revisit mixin naming and scope of use once we apply additional animation effects to quest
  world thingns.
*/

@mixin animation-layer($component, $varis) {
  .#{$component} {
    &__animation-layer {
      width: 100%;
      height: 100%;
      margin: auto;
      position: absolute;
      top: 0;
      pointer-events: none;

      @each $variation-name, $variation-path in $varis {
        &--#{$variation-name} {
          height: 100%;
          width: 100%;
          background: $variation-path no-repeat;
        }
      }
    }
  }
}

$variations: (
    celebration-sparkle-01,
    url("./../images/quest-themes/planet/celebration-sparkle-01.svg")
  ),
  (celebration-sparkle-02, url("./../images/quest-themes/planet/celebration-sparkle-02.svg")),
  (celebration-sparkle-03, url("./../images/quest-themes/planet/celebration-sparkle-03.svg"));

@include animation-layer(ActivityStation, $variations);
