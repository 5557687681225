/* Rocky plus hand class*/
.rocky-jetpack {
  position: fixed;
  width: 80%;
  height: 80%;
  left: -20vw;
  top: 100vh;
  animation: fly-in 1s 1s forwards;
  -webkit-animation: fly-in 1s 1s forwards;
  z-index: 1;
}

#rocky {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.rocky-image-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 530px;
  height: 100%;
  vertical-align: middle;
}

/* Rocky-image hovering class */
.hovering {
  animation: hovering 0.3s 2s infinite;
  -webkit-animation: hovering 0.3s 2s infinite;
}

/*Make hand pulse while waiting for click*/
.hand-container {
  position: absolute;
  left: 330px;
  top: 20px;
  width: 388px;
  height: 100%;
  vertical-align: middle;
}

/* hand pull-back */
.hand-container:hover {
  transition: 0.15s;
  transform: scale(0.9);
}

#hand {
  animation: hand-pulse 3s infinite;
}

#hand-hit {
  visibility: hidden;
}

.fire {
  animation: fire-anim 0.5s 0s infinite alternate;
  width: 64px;
}

.fire-container {
  position: absolute;
  left: 0px;
  top: 10px;
  width: 530px;
  height: 100%;
  vertical-align: middle;
}

#fire1 {
  position: absolute;
  top: 110px;
  left: 6px;
}

#fire2 {
  position: absolute;
  top: 120px;
  left: 335px;
}

/* Rocky flyout class */
.rocky-fly-out {
  position: absolute;
  width: 80%;
  height: 80%;
  left: 10vw;
  top: 10vh;
  animation: fly-out 3s cubic-bezier(0.2, -0.6, 0.2, 0) forwards;
  -webkit-animation: fly-out 3s cubic-bezier(0.2, -0.6, 0.2, 0) forwards;
}

.spinner-container {
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

/*START HIGH FIVE MARK ANIMATION*/
#hf-mark {
  height: 90vh;
  width: 90vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  text-align: center;
  opacity: 0;
}

#hf-mark > img {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.hf-mark-show {
  animation: high-five-anim 3s 3s forwards;
  -webkit-animation: high-five-anim 3s 3s forwards;
}

/* Rocky fly-in animation */
@keyframes fly-in {
  100% {
    transform: translate(30vw, -90vh);
  }
}

@-webkit-keyframes fly-in {
  100% {
    transform: translate(30vw, -90vh);
  }
}

/* Rocky hovering animation */
@keyframes hovering {
  15% {
    transform: translate(0px, -3px);
  }

  40% {
    transform: translate(5px, 0px);
  }

  60% {
    transform: translate(0px, 3px);
  }

  80% {
    transform: translate(-5px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@-webkit-keyframes hovering {
  15% {
    -webkit-transform: translate(0px, -3px);
  }

  40% {
    -webkit-transform: translate(5px, 0px);
  }

  60% {
    -webkit-transform: translate(0px, 3px);
  }

  80% {
    -webkit-transform: translate(-5px, 0px);
  }

  100% {
    -webkit-transform: translate(0px, 0px);
  }
}

/* hand pulse */
@keyframes hand-pulse {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(0.95);
  }

  20% {
    transform: scale(1);
  }
}

/* high five scale animation */
@keyframes high-five-anim {
  from {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0.1;
  }

  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

/* fire */
@keyframes fire-anim {
  from {
    opacity: 0;
  }

  to {
    opacity: 1s;
  }
}

/* Rocky fly-out animation */
@keyframes fly-out {
  70% {
    transform: translate(0px, 0px);
  }

  100% {
    transform: translate(200px, -900px);
  }
}

@-webkit-keyframes fly-out {
  70% {
    -webkit-transform: translate(0px, 0px);
  }

  100% {
    -webkit-transform: translate(200px, -900px);
  }
}

/*START CONFETTI*/
@keyframes confetti-slow {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }

  100% {
    transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg);
  }
}

@keyframes confetti-medium {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }

  100% {
    transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg);
  }
}

@keyframes confetti-fast {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }

  100% {
    transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg);
  }
}

/* Confetti container*/
.confetti-parent-container {
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transition: 0.5s;
  transition-delay: 2.5s;
  z-index: 0;
}

.container-show {
  opacity: 1;
}

.confetti-container {
  perspective: 700px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.confetti {
  position: absolute;
  z-index: 1;
  top: -10px;
  border-radius: 0%;

  &--animation-slow {
    animation: confetti-slow 2.25s linear 1 forwards;
  }

  &--animation-medium {
    animation: confetti-medium 1.75s linear 1 forwards;
  }

  &--animation-fast {
    animation: confetti-fast 1.25s linear 1 forwards;
  }
}

/*END CONFETTI*/
