@import "font-didact-gothic";
@import "font-open-sans";
@import "bulma.custom";
@import "bulma.overrides";
@import "modifiers";
@import "progress";
@import "video-recording";
@import "video-player";
@import "activity-card";
@import "learnosity";
@import "learnosityV2";
@import "mathjax";
@import "animations";
@import "stations";
@import "login";
@import "text-to-speech";
@import "web-activity";
@import "ebook";
@import "~@eSpark/buttons";
@import "highfive";
@import "@espark/evaluate-client/styles";

// disallow copy and paste within the app
// this prevents younger students from being confused by various contextual menus
// see https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting#4407335
// and https://stackoverflow.com/questions/5995210/disabling-user-selection-in-uiwebview
* {
  @extend .is-unselectable;
}

html {
  overflow-y: visible;
  border: 0 !important;
}

body {
  max-width: 100vw;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: auto !important;
  font-size: 16px;
  touch-action: manipulation;
}

.main {
  min-height: 100vh;
}

button {
  color: currentColor;
  cursor: pointer;
  outline: none;
  border: none;

  &[disabled] {
    box-shadow: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.preloader {
  transition: opacity 500ms ease-in, height 1ms ease-in 500ms;
  opacity: 1;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1000000;
  background-color: #e3f5fe;

  &.loaded {
    opacity: 0;
    height: 0;
  }
}

// Move this so it doesn't conflict with the next activity button
.elm-mini-controls {
  left: 200px !important;
  right: unset !important;
  bottom: 0 !important;

  background-color: rgba(61, 61, 61, 0.3);

  display: block;

  &.production {
    display: none;
  }
}

// TODO: Remove this when styling notification modal
// Temporary to exit modal, which is otherwise is obstructed by the logout button
.notification > .delete {
  left: 0.5rem;
}

input,
textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: none;
  margin-top: 62px;
  margin-bottom: 30px;
}

::-webkit-scrollbar-thumb {
  width: 14px;
  box-shadow: none;
  border-radius: 30px;
}

.modal {
  z-index: 10000;
}
