.web-app-instructions {
  scrollbar-color: transparent #ffffff;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 120px;
  }
  &::-webkit-scrollbar-track {
    margin: 0;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #ffffff;
  }
}

/* Temporary until we fix the button repo to support overriding the color of the glyph */
#footer-visibility-button {
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  bottom: 110px;
  box-shadow: none;
  color: #14a1ae !important; /* override color of arrow icon */
  background-color: white;
}
