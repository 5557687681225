.video-recording-custom-loading {
  position: relative;

  &::before,
  &::after {
    content: "";
    height: 100%;
    width: 100%;

    position: absolute;
    clip-path: circle(50% at 0 100%);
    transform-origin: bottom left;
    transform-style: preserve-3D;
    bottom: 50%;
    left: 50%;
  }

  &::before {
    animation: spin-bottom-right 2s cubic-bezier(0.91, 0.01, 0.8, 0.82) infinite;
    background-color: #99ced4;
    z-index: -2;
  }
  &::after {
    animation: spin-bottom-right 2s 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    background-color: #14a1ae;
    z-index: -1;
  }
}

@keyframes spin-bottom-right {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
