.login-input {
  // ensure that we always have a border so it doesn't jump
  border: 6px solid transparent;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);

  &:hover {
    border-color: transparent;
  }

  &:focus,
  &:active {
    border: 6px solid #66dbdf;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  }
}
