/*
Displayed MathJax dom elements will have tts-ignore thus will not receive .tts-active class,
these elements will still need to be highlighted. MathJax jax formatted elements we will highlight
the whole element since we are unable to detect the exact moment it is being read aloud with css selectors

Response options with HAOs, have the tts-active class added to the response options's container, as opposed to the
inner spans like the regular response options. We have added additional styles for the response options with HAOs.
.lrn-es-has-hao-options .tts-active span will make sure the inner text of response options get highlighted.
*/
.tts-active,
.lrn-es-has-hao-options .tts-active span,
.es-mathjax-formatted.tts-active .distractor-rationale--text *,
.es-mathjax-formatted .lrn-es-tts-active .espark-text-prompt,
.es-mathjax-formatted .lrn-es-tts-active .MathJax,
.es-mathjax-formatted .lrn-es-tts-active .lrn_assoc_question,
.es-mathjax-formatted .lrn-es-tts-active .lrn_item,
.es-mathjax-formatted .lrn-es-tts-active.lrn-possible-answer .lrn_contentWrapper {
  background-color: #fff8b0;
  color: #4c4c4c;
}
