/*
  Learnosity injects code into our app. We can't fully control the
  display or the tags they generate so we have to override aspects
  here.

  their Questions API has a nice styling demo
  https://docs.learnosity.com/demos/isolation/questions_styles.php
  https://docs.learnosity.com/demos/isolation/questions_styles.php

  however, much of what we have to style is at the lower-level
  Items API.
  https://docs.learnosity.com/assessment/items
 */

// As long as we nest under this selector that we wrapped
// the learnosity injection tag, we'll have higher specificity and
// can easily overrid the default styles.
// Learnosity has much of its content at 200 z-index.
$z-index-precedence: 201;
$text-orange: #c78216;

@mixin flex-column-vertically-centered {
  display: flex;
  flex-direction: column;
  > * {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.critical-thinking-challenge,
.webapp,
.youtubevideo {
  .level-item {
    // this is a modification to the bulma level flex settings that
    // allows scrolling to the top of tall content.
    margin: auto;
  }
}

.prequiz,
.postquiz {
  &.is-activity-practice {
    .monster {
      @extend .is-reflected-horizontal;
      left: -60px;
      z-index: 11 !important;
    }
  }

  > .content {
    height: 100vh !important;
  }
}

/*
  We nest everything under the id of the component we mount the learnosity content within so
  we have higher specificity and the styles override the ones learnosity applies.
*/

/*
    Base Overrides
      - Our defaults for font etc.
      - Fit content to the entire width of the screen.
  */
#learnosity-content {
  .lrn,
  .lrn * {
    text-rendering: auto !important;
    font-family: $family-sans, LearnosityMath, LearnosityIconsRegular !important;
    font-size: 20px;

    strong {
      color: unset;
    }
  }

  .lrn,
  .lrn *:not(.espark-text-answer),
  .lrn *:not(.espark-text-answer *) {
    font-size: 20px;
  }

  .lrn ol {
    padding-left: 1em;
    li::before {
      width: 1em;
    }
  }

  .MathJax {
    font-size: 24px !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: none;
    margin-top: 62px;
    margin-bottom: 30px;
  }

  ::-webkit-scrollbar-thumb {
    width: 14px;
    box-shadow: none;
    border-radius: 30px;
  }

  .critical-thinking-challenge &,
  .webapp &,
  .youtubevideo & {
    .lrn-assess {
      // note: setting this to max-width causes images to not appear, apparently
      // learnosity does some width calculations on the fly and will make images
      // zero width if this parent node has a min-width but not if it has a width.
      width: calc(100vw - 100px);
    }
  }

  .prequiz &,
  .postquiz & {
    .lrn-group-middle,
    .slides-container,
    .col-xs-12,
    .lrn_widget,
    .row,
    .content.lrn > .row > .col-xs-12 > div:last-child {
      height: 100vh;
    }

    .lrn_widget {
      overflow: overlay;
    }

    .lrn_widget::-webkit-scrollbar {
      display: none;
    }

    .app-layout {
      height: 100vh;
      width: 100vw;
    }

    .lrn-region.items .slides-control .content,
    .lrn-region.items .slides-control .content .row,
    .lrn-region.items .slides-control .content .row .col-xs-12,
    .lrn_widget.lrn_qr.lrn_clearfix,
    .lrn-assess,
    .lrn_question {
      padding: 0;
      margin: 0;
    }
  }

  /*
    Listen button styles
  */

  .listen-control-button--stimulus {
    margin-bottom: 30px;

    i {
      // unfortunate that we have to duplicate knowledge from the button library here, but that's cascading style sheets for you
      // since we set a global value some lines above for lrn content
      font-size: 36px;
    }
  }

  .listen-button-container {
    display: table-cell;
    vertical-align: middle;
  }

  .listen-control-button--answer {
    display: block;
    z-index: 20;
    cursor: pointer;
    background: #ffc949;
    border-radius: 50%;
    font-family: "espark-icons";
    display: inline-block;
    color: white;

    i {
      font-size: 15px;
      display: flex;
      place-content: center;
    }

    @at-root #learnosity-content .lrn_stimulus .listen-control-button--answer {
      height: 50px;
      width: 50px;
      font-size: 25px;
      position: relative;
      top: -9px;
      span {
        height: 50px;
        width: 50px;
        font-size: 25px;
        position: relative;
        top: 12px;
      }

      i {
        top: 0px;
        right: 0px;
        bottom: 0px;
        &.es-play {
          left: 3px;
        }
      }
    }

    @at-root #learnosity-content .lrn-possible-answer .listen-control-button--answer {
      height: 30px;
      width: 30px;
      font-size: 15px !important;
      position: relative;
      top: 4px;
      span {
        font-size: 15px !important;
        height: 30px;
        width: 30px;
        position: relative;
        top: 7px;
      }
      i {
        &.es-play {
          left: 2px;
        }
      }
    }
  }
}

/*
  Base item styles
*/
.learnosity-item {
  // Pre/Post-Quiz item styles across layours
  .prequiz #learnosity-content &,
  .postquiz #learnosity-content & {
    background-color: #e3f5fe;
    // move me later
    .espark-text-prompt {
      margin-bottom: 0 !important;
    }
    .quiz-banner {
      filter: drop-shadow(4px -1px 10px rgba(0, 0, 0, 0.3));
      width: 100%;
      .quiz-banner-body {
        display: flex;
        overflow: hidden;
        flex-direction: column;
        font-size: 28px !important;
        background-color: white;
        line-height: 1.3em;
        width: 100%;
        // we need this clip path so that ocntent in safari doesn't overflow the parent
        // container which has drop-shadow applied. Drop-shadow area is considered within the
        // area of the element in Safari, so the inner content scrolled through the shadow.
        clip-path: inset(0 0 0 0);
        @include flex-column-vertically-centered;
        .quiz-banner-content-wrapper {
          overflow-y: scroll;
          margin-right: 20px;
          padding-right: 16px;
          margin-top: auto;
          margin-bottom: auto;
          p:last-child {
            margin-bottom: 0;
          }
          img {
            display: block;
            margin: 20px auto;
            max-width: calc(100vw - 100px);
            max-height: unset;
          }

          .espark-image-passage-content img {
            max-width: unset;
            max-height: unset;
          }
        }
      }

      .quiz-banner-bubble-tail {
        fill: white;
        position: relative;
        top: -2px;
      }
      #quiz-banner {
        display: none;
      }
    }

    .passage-node-wrapper {
      background: white;
    }

    .lrn_sharedpassage {
      font-size: 24px;
      line-height: 1.3em;
    }

    .lrn-numbered-paragraph::before {
      width: 0;
    }

    .lrn-feature-heading {
      text-align: center;
      padding-left: 20px;
    }
  }
}

/*
  Item styles for passage layout
*/
.lrn-feature-type-sharedpassage {
  .lrn-feature-heading {
    text-align: center;
  }

  .espark-passage-content {
    // for the Audio V1 passage play button
    margin-left: 100px;
  }
}

/*
  Item styles for non-passage layout
*/
.non-passage-layout {
  // Pre/Post-Quiz item specific styles
  .prequiz #learnosity-content &,
  .postquiz #learnosity-content & {
    .quiz-banner {
      height: 50vh;
      .quiz-banner-body {
        height: 100%;
        .quiz-banner-content-wrapper {
          padding: 50px;
        }
      }
    }
    &.has-passage {
      .quiz-banner .quiz-banner-body .quiz-banner-content-wrapper {
        padding-left: 120px;
      }

      .espark-text-prompt {
        margin-bottom: 0.5em !important;
        &:last-child {
          margin-bottom: 1em !important;
        }
      }
      .espark-question-prompt {
        margin-top: 1em;
      }

      .espark-passage-content {
        padding-bottom: 50px;
      }
    }
  }
}

/*
  Item styles for passage layout
*/
.passage-layout {
  // Pre/Post-Quiz item specific styles
  .prequiz #learnosity-content &,
  .postquiz #learnosity-content & {
    .lrn_sharedpassage {
      height: 40vh;
      overflow-y: scroll;
      margin-right: 20px;
      margin-bottom: 0;
      padding: 70px 86px 50px 120px;
    }

    .quiz-banner {
      height: 15vh;
      .quiz-banner-body {
        height: 15vh;
        .quiz-banner-content-wrapper {
          padding-left: 36px;
        }
      }
    }

    .lrn_response_wrapper {
      height: calc(45vh - 120px);
    }
  }
}

.espark-text-answer {
  thspan {
    font-size: inherit !important;
  }
}

/*
  Response wrapper styles
*/
.lrn_response_wrapper {
  // Centering image based question types (hotspot, cloze)
  .lrn_hotspot & .lrn-response-validate-wrapper,
  .lrn_imageclozetext & .lrn_cloze_response_container {
    margin: 0 auto !important;
  }
  .prequiz #learnosity-content &,
  .postquiz #learnosity-content & {
    margin-left: 83px;
    margin-right: 55px;
    height: calc(50vh - 140px);
    font-size: 28px;
    padding: 0 20px;
    // TODO: fix me -- quiz implementation
    @at-root .choice-layout .prequiz #learnosity-content .lrn_response_wrapper,
      .choice-layout .postquiz #learnosity-content .lrn_response_wrapper {
      margin-left: 0;
    }
    .lrn_response {
      height: 100%;
    }
    ul.lrn-response-validate-wrapper.lrn_mcqgroup {
      display: flex;
      flex-flow: row wrap;
      height: 100%;
      justify-content: space-between;
      margin-top: 20px;
      &.has-image-responses {
        margin-right: 10px !important;
      }
      li {
        margin: 0 !important;
        width: calc(50% - 10px) !important;
        min-height: calc(50% - 20px);
        margin-top: 20px;
        padding-bottom: 20px;
        border-radius: 20px;
        border-color: transparent !important;

        input + label {
          height: 100%;
          border-radius: 20px;
          background: white;
          box-shadow: none !important;
          font-size: 24px;
          display: flex !important;
          flex-direction: column !important;
          align-content: center;
          line-height: 1.2em;
          .lrn-possible-answer {
            margin: auto !important;
            .lrn_contentWrapper {
              padding: 12px;
              text-align: center;
              span {
                text-align: center;
              }
            }
          }
          &::before {
            content: "";
          }
        }
        input:not([disabled]) + label {
          border: 2px dashed $text-orange;
          color: $text-orange;
        }

        input[disabled] + label {
          border: 2px dashed #d9d9d9;
          color: #dfdfdf;
        }
        &.lrn_selected {
          input + label {
            border: 4px solid $text-orange;
          }
        }

        &.lrn_valid,
        &.lrn_correct {
          input + label {
            border: 4px solid #64ad4c;
            color: #64ad4c;
          }
        }
        &.lrn_incorrect {
          input + label {
            border: 4px solid #d9d9d9;
          }
        }
      }
      &.has-image-responses {
        margin-right: 20px;
        margin-top: 10px;
        flex-wrap: nowrap;
        justify-content: space-between;
        li {
          margin: 0 10px;
          height: 100%;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
        img {
          max-height: calc(50vh - 180px);
          display: block;
        }
      }
    }
  }

  .youtubevideo #learnosity-content &,
  .webapp #learnosity-content &,
  .critical-thinking-challenge #learnosity-content & {
    // In assessmentDisplay, answer choice text sizes are updated to match the largest text size
    // e.g. one is 30px, set all to be 30px
    // The padding, however, is based on ems (e.g. 0.7em) and is not re-calculated
    // after size updates. Instead, just standardize it:
    .lrn_contentWrapper {
      padding: 18px 0 14px 10px !important;
    }
    // to prevent the logout button from covering the question
    .lrn_question {
      margin-top: 15px;
    }
  }
} // .lrn_response_wrapper

#distractor-rationale {
  font-size: 30px;
  line-height: 1.4;

  border: 1px solid #4c4c4c;
  border-radius: 6px;

  padding: 30px 50px 20px 50px;
  margin-top: 20px;
  margin-left: 5px;
}
