// Component.Video wraps the video in a figure, but we need to style the video element itself
figure.video-player {
  video {
    // Do not use position: fixed (will break on ipad)
    width: 100%;
    height: calc(100% - 68px);
  }

  &.video-player-completed-sequential {
    border-radius: 50px;

    video {
      border-radius: 50px;
      height: 100%;
    }
  }

  &.video-feedback-player {
    video {
      height: 100%;
    }
  }
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  #youtube-player {
    // Do not use position: fixed (will break on ipad)
    width: 100%;
    height: calc(100% - 110px);
  }
}
