// Ui.Media.image wraps it in a div, but we need to style the
// img itself in order to get correct sizing
// NOTE (csain): Future refactor of activity card styling using bulma
.activity-card-image-wrapper {
  width: 200px;
  height: 150px;
  max-height: 150px;
  background: white;
  text-align: center;

  img {
    height: 150px;
    width: auto;
    margin: auto;
  }
}
