// Import the initial variables and functions
@import "~bulma/sass/utilities/all";

$blue: #4b95e0;
$blue-invert: findColorInvert($blue);

// Override sans font
$family-sans: "Didact Gothic", sans-serif;

// Use the new turquois as the primary color
$primary: $blue;
$primary-invert: $blue-invert;

// Use the new sans family
$family-primary: $family-sans;

$size-4: 1.7rem;

/*
  Add additional colors to the bulma color map
 */

$button-yellow: #f7ca6b;

$text-yellow: #f1a938;
$text-yellow-invert: findColorInvert($text-yellow);

$text-orange: #ba570d;
$text-orange-invert: findColorInvert($text-orange);

$text-bright-red: #b22212;
$text-bright-red-invert: findColorInvert($text-bright-red);

$text-dark-red: #a52020;
$text-dark-red-invert: findColorInvert($text-dark-red);

$text-purple: #7c3eb7;
$text-purple-invert: findColorInvert($text-purple);

$text-blue: #0d4590;
$text-blue-invert: findColorInvert($text-blue);

$text-green: #2c8441;
$text-green-invert: findColorInvert($text-green);

$text-colors: (
  "text-yellow": ($text-yellow, $text-yellow-invert),
  "text-orange": ($text-orange, $text-orange-invert),
  "text-bright-red": ($text-bright-red, $text-bright-red-invert),
  "text-dark-red": ($text-bright-red, $text-dark-red-invert),
  "$text-purple": ($text-purple, $text-purple-invert),
  "$text-blue": ($text-light, $text-blue-invert),
  "$text-green": ($text-green, $text-green-invert)
);

$colors: map-merge($colors, $text-colors);

$modal-card-head-background-color: white;
$modal-card-head-border-bottom: 0;
$modal-card-foot-border-top: 0;

/*
  Bulma is completely modular -- include only what we use.
  The commented out pieces aren't currently in use, but we can
  add them whenever we want.

  Be sure to uncomment it if you want to use it!
  https://bulma.io/documentation/overview/modular/

  if we upgrade Bulma, we'll need to make sure these are still
  accurate and add any new modules to our list.
*/

@import "~bulma/sass/base/all";

// Elements
//
// @import "~bulma/sass/elements/box";
@import "~bulma/sass/elements/button";
@import "~bulma/sass/elements/container";
// @import "~bulma/sass/elements/content";
@import "~bulma/sass/elements/form";
@import "~bulma/sass/elements/icon";
@import "~bulma/sass/elements/image";
@import "~bulma/sass/elements/notification";
// @import "~bulma/sass/elements/progress";
// @import "~bulma/sass/elements/table";
// @import "~bulma/sass/elements/tag";
@import "~bulma/sass/elements/title";
@import "~bulma/sass/elements/other";

// Components
//
// @import "~bulma/sass/components/breadcrumb";
// @import "~bulma/sass/components/card";
// @import "~bulma/sass/components/dropdown";
@import "~bulma/sass/components/level";
@import "~bulma/sass/components/media";
// @import "~bulma/sass/components/menu";
// @import "~bulma/sass/components/message";
@import "~bulma/sass/components/modal";
// @import "~bulma/sass/components/navbar";
// @import "~bulma/sass/components/pagination";
@import "~bulma/sass/components/panel";
// @import "~bulma/sass/components/tabs";

// Grid
@import "~bulma/sass/grid/all";

// Layout
@import "~bulma/sass/layout/hero";
@import "~bulma/sass/layout/section";
// @import "~bulma/sass/layout/footer";
