// Animations
// --------------------------
@import "~animate.css";

.animated {
  &.ease-in-out {
    animation-timing-function: ease-in-out;
  }
}

.throb {
  animation-name: throb;
}

.is-spinning {
  animation: spin 2s infinite linear;
}

.is-pulsing {
  animation: pulse 1s infinite steps(8);
}

.pulse-lg {
  animation-name: pulse-lg;
  animation-duration: 1.5s;
}

.pulse-lg-centered {
  animation-name: pulse-lg-centered;
  animation-duration: 1.5s;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.complete-activity-bulb {
  animation: grow 0.25s ease-out forwards, shrink 0.25s ease-in 1s forwards;
}

@keyframes grow {
  0% {
    opacity: 0;
  }
  0% {
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes shrink {
  0% {
    opacity: 1;
  }
  0% {
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
  }
  100% {
    transform: scale(0.5, 0.5);
  }
}

@keyframes throb {
  20% {
    transform: scale(1.1);
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.5);
  }
  40% {
    transform: scale(1);
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
  }
}

@keyframes pulse-lg {
  from {
    transform: scale3d(1, 1, 1);
  }

  25% {
    transform: scale3d(1.15, 1.15, 1.15);
  }

  50% {
    transform: scale3d(1, 1, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse-lg-centered {
  from {
    transform: scale3d(1, 1, 1) translate(-50%, -50%);
  }

  25% {
    transform: scale3d(1.15, 1.15, 1.15) translate(-45%, -45%);
  }

  50% {
    transform: scale3d(1, 1, 1) translate(-50%, -50%);
  }

  to {
    transform: scale3d(1, 1, 1) translate(-50%, -50%);
  }
}

@keyframes iframe-webapp-footer-show {
  from {
    bottom: -100px;
  }

  to {
    bottom: 0px;
  }
}
